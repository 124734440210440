<template>
	<span class="no-gutters justify-content-end">
		<span
			:id="`popover-duplicated-${type}`"
			class="pointer drb-pbv-popover-trigger"
			tabindex="0">
			<i class="fa fa-info-circle text-danger" />
		</span>
		<b-popover
			:target="`popover-duplicated-${type}`"
			triggers="click blur"
			custom-class="drb-pbv-popover"
			boundary-padding="0"
			:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'right'">
			<b-alert
				class="p-2 rounded-0 mb-1 border-bottom"
				show
				variant="secondary-darker">
				<div class="row no-gutters">
					<div class="col">
						<span
							class="mb-0 pt-3 align-center h6 d-inline">
							{{ translate("duplicated_info") }}
						</span>
						<a
							class="text-success h6 pointer float-right mr-2 mb-0"
							:title="translate('copy')"
							@click="copyInfo">
							<span class="fa fa-copy" />
						</a>
					</div>
				</div>
			</b-alert>
			<div class="p-1 pb-2">
				<template>
					<div class="pbv-popover">
						<div
							v-if="true"
							class="table-responsive mb-0">
							<table class="table text-nowrap">
								<thead>
									<tr class="text-center">
										<th class="p-2 text-center">
											{{ '#' }}
										</th>
										<th class="p-2 text-left">
											{{ translate('user_id') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('username') }}
										</th>
										<th
											class="p-2 align-middle">
											{{ translate('type') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in info.visible_records"
										:key="index">
										<td class="p-2 text-center">
											{{ index + 1 }}
										</td>
										<td class="p-2 text-center">
											<a
												class="badge badge-primary"
												:href="getEditLink(item)"
												target="_blank"
												rel="noopener noreferrer">
												{{ item.id }}
											</a>
										</td>
										<td class="p-2 text-left">
											{{ item.username }}
										</td>
										<td
											class="p-2 align-middle text-right">
											{{ translate(item.type) }}
										</td>
									</tr>
								</tbody>
							</table>
							<i
								v-if="info.non_visible_records > 0"
								class="text-muted">
								{{ translate('and_more_records', { records: info.non_visible_records }) }}
							</i>
							<div ref="reference" />
						</div>
					</div>
				</template>
			</div>
		</b-popover>
	</span>
</template>
<script>
import { Grids, Users } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { customerRoles, distributor, admin } from '@/settings/Roles';

export default {
	name: 'DuplicatedFields',
	messages: [Grids, Users],
	mixins: [WindowSizes],
	props: {
		info: {
			type: Object,
			default() {
				return {};
			},
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			distributor,
			customerRoles,
			admin,
			alert: new this.$Alert(),
		};
	},
	methods: {
		getEditLink(info) {
			if (this.customerRoles.includes(info.type)) {
				return `/manage/customers/${info.id}/update-user`;
			}

			if (info.type === distributor) {
				return `/distributor-information/${info.id}`;
			}

			if (this.admin.includes(info.type)) {
				return `/manage/corporates/${info.id}/update-corporate`;
			}

			return '';
		},
		copyInfo() {
			const formattedTextArray = this.info.visible_records.map((userInfo) => `${userInfo.username} (${userInfo.id}) - ${this.translate(userInfo.type)}`);
			const formattedText = formattedTextArray.join('\n');
			const auxTextArea = document.createElement('textarea');
			auxTextArea.value = formattedText;
			this.$refs.reference.appendChild(auxTextArea);
			auxTextArea.select();
			auxTextArea.setSelectionRange(0, 99999);
			document.execCommand('copy');
			this.$refs.reference.removeChild(auxTextArea);
			this.alert.toast('success', this.translate('copied'));
		},
	},
};
</script>
<style>
.drb-pbv-popover-trigger:focus {
	outline: none;
}
.drb-pbv-popover {
	border-radius: 0;
	max-width: 500px;
}
.drb-pbv-popover > .popover-body {
	padding: 0;
	max-width: 500px;
}
.pbv-popover {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}
.pbv-popover::-webkit-scrollbar {
	width: 5px;
}
.pbv-popover::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
}
.pbv-popover::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear,height .2s linear,width .2s ease-in-out,border-radius .2s ease-in-out;
	right: 2px;
	width: 6px
}
</style>
